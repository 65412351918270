// This file is imported after all core/plugin stylesheets.
// This allows you to overwrite any styling you want.
// Keep in mind variables and mixins defined here will have no effect on earlier imported styling.
// If you want that, you should define them in includes.scss.
// Anything defined in the includes files (from core and theme) is available for use.

@import "./wrp-icons.scss";

skr-app {
    background: #f6f6f6 url("~@skryv/bundle/theme/assets/images/background-pattern-gradient.png") top repeat-x;

  skr-brand-logo {
    // create the diagonal line
    .menu-icon {
      background-color: #FFE615;
      transform: skewX(23deg);
      transform-origin: 100% 100%;
      position: absolute;
      padding-left: 6px;
      left: 0;
      top: 0;
    }

    .menu-icon img {
      height: 44px;
      padding: 5px 14px;
      transform: skewX(-23deg);
    }

  }

  skr-menu-layout{
    .menu .navigation-main {
        height: 44px;
    }

    .menu a {
        color: #333333;
    }

    .menu .menu-wrapper{
        color: #333333;
    }
  }

  .tabs .badge{
    border: 1px solid #333333;
    color: #333333;
  }

  .workspace-header .navigation-main {
      height: 44px;
      color: #333333;
  }

  .workspace-footer{
    background: #E8EBEE;
  }

  .skr-shortcut-container.is-emphasized{
    background-color: #E8EBEE;
  }

  skr-breadcrumbs{
    .breadcrumb a {
        color: #333333;
    }
  }

  skr-dossier-tile-count .counter-number{
    background-color: #FFE615;
  }

  .collapsable-information-button {
    color: #ffffff;
    background-color: #0055CC;
  }

  .collapsable-information-header{
    color: #0055CC;
  }

  skr-dossier-tile-active-task .info-block{
    border-bottom: 0.5px solid #dddddd;
  }

  skr-dossier-tile-active-task .info-block.task{
    color: #333333;
  }

  .skr-actions .subitem-info{
    color: #333333;
  }

  .no-active-task{
    border-bottom: 0.5px solid #dddddd;
    color: #333333;
  }

  .workspace-side-menu {
    top: 44px;
  }
}

// pdf styling: assemble/src/main/resources/pdf/header.hbs
